import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPage = ({ data }) => (
  <Layout>
    <SEO title="Blog" />
    <div style={{
      
    }}>
      <h2 style={{paddingBottom: `1rem`}}>Articles</h2>
      {
        data.allMarkdownRemark.edges.map(({node}) => (
          <div key={node.id}>
            <Link to = {node.fields.slug}>
              <p className="postListing">
              { node.frontmatter.title }</p>
            </Link>
          </div>

        ))
      }
    </div>
  </Layout>
)

export default BlogPage

export const query = graphql`
  query {
   allMarkdownRemark (sort: { fields: [frontmatter___date], order: DESC }){
    edges {
      node {
        frontmatter {
          date
          title
        }
        fields {
          slug
        }
      }
    }
    totalCount
  }
  }
`
